import './App.css';

import { Navigate, Route, Routes, useLocation, useParams } from "react-router-dom";

import { AppIdentityRoutingModule } from './modules/App.Identity.RoutingModule';
import { AppPortal } from './lib/layouts/portal/AppPortal';
import { AppRoutingModule } from './modules/App.RoutingModule';
import { ProjectService } from './features/projects/services/ProjectService';
import { RequireAuth } from './lib/infrastructure/identity/components/RequireAuth';
import { SplashScreen } from './common/pages/splash-screen/SplashScreen';
import { useEffect } from 'react';
import { useIdentityIsProcessing } from './lib/infrastructure/identity/hooks/useIdentityIsProcessing';
import { useSetSelectedProject } from './lib/infrastructure/ui/UIServices';

export function App() {

  const isProcessing  = useIdentityIsProcessing();
  const [selectedProject, setSelectedProject] = useSetSelectedProject();
  const location = useLocation();
  const { projectId } = useParams();
  var projectSvc = new ProjectService();

  useEffect(() => {
    // if (projectId && projectId != selectedProject?.id) {
    //   projectSvc.getById(Number(projectId)).then((proj) => {
    //     setSelectedProject({ id: projectId, name: proj.name });
    //   });
    // }
    // if (projectId === undefined && selectedProject) {
    //   setSelectedProject(undefined);
    // }
    //console.log("aaa" + JSON.stringify(location));


    // TODO: Verificar rotas, e resolver!!
    if (location.pathname.search("/projects/")) {
      setSelectedProject(undefined);
    }

  }, [projectId, location]);


  if (isProcessing) return <SplashScreen />;

  return (
    <AppPortal>

      <Routes>
        <Route path="/" element={<Navigate to="/projects" />} />
        <Route path="/notfound" element={<>Not found</>} />
        <Route path="/identity/*" element={<AppIdentityRoutingModule />} />
        <Route path="*" element={<RequireAuth><AppRoutingModule /></RequireAuth>} />
      </Routes>

    </AppPortal>
  )
}
