import { IdentityService } from "../IdentityService";
import { SplashScreen } from "../../../../common/pages/splash-screen/SplashScreen";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

interface IIdentityActionProps {

    exec: (svc : IdentityService, calledFromUrl: string | undefined | null) => void;
}

export function IdentityAction(props: IIdentityActionProps) {
    const [searchParams] = useSearchParams ();
    useEffect(() => {
        props.exec(IdentityService.Global,searchParams.get("url"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    return (
        <SplashScreen/>
    );

}
