import './SessionExpiredPage.css';

import {useNavigate, useSearchParams} from "react-router-dom";

import { Button } from '../../../lib/components/buttons/Button';
import { SpaceBackgroundLayout } from '../../../lib/layouts/space-background/SpaceBackgroundLayout';
import { useMemo } from 'react';

export function SessionExpiredPage() {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams ();

    const redirect = useMemo(() => searchParams.get('url')?.includes("expired") ? "" : `?url=${searchParams.get('url')}`, [searchParams]);
    
    return (
        <SpaceBackgroundLayout>

            <h1 className="logo-text">Orbital Watch</h1>

            <h1>Session Expired!</h1>
            <div><Button text={"Login"} type="primary" onClick={() => {
                  localStorage.clear();
                  sessionStorage.clear();
                  navigate(`/identity/login${redirect}`);
            } } /></div>

        </SpaceBackgroundLayout>
    )
}
